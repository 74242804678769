<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

export interface BasePopoverProps {
  // TODO: Add other placements + support for "auto" placement.
  placement?: "bottom" | "bottom-right" | "bottom-left";
  contentClass?: string;
}

const props = withDefaults(defineProps<BasePopoverProps>(), {
  placement: "bottom",
});
const popoverElRef = ref(null);
const isPopoverVisible = ref(false);
const onClose = () => isPopoverVisible.value = false;
const onOpen = () => isPopoverVisible.value = true;
const onToggle = () => isPopoverVisible.value = !isPopoverVisible.value;

onClickOutside(popoverElRef, () => onClose());
</script>

<template>
  <div ref="popoverElRef" class="basePopover">
    <slot :visible="isPopoverVisible" :toggle="onToggle" :open="onOpen" :close="onClose" />
    <div
      v-if="isPopoverVisible"
      class="basePopover__content max-h-[calc(90vh-105px)] overflow-auto"
      :class="[`basePopover__content_${props.placement}`, props.contentClass]"
    >
      <slot name="content" :visible="isPopoverVisible" :toggle="onToggle" :open="onOpen" :close="onClose" />
    </div>
  </div>
</template>

<style lang="scss">
.basePopover {
    @apply relative;

    .basePopover__content_bottom {
        @apply absolute z-50 min-w-max;
    }

    .basePopover__content_bottom-right {
      @apply absolute z-40 right-0 w-[300px];
      top: calc(100% + 1.75rem);
    }
    .basePopover__content_bottom-left {
      @apply absolute z-40 left-0 w-[300px];
      top: calc(100% + 3rem);
    }
}
</style>
